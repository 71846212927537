export function initCollapsible (collapsible) {
  $(collapsible).on('show.bs.collapse', function(e) {
    $(e.target.parentElement).addClass("activating")
  });
  $(collapsible).on('shown.bs.collapse', function(e) {
    $(e.target.parentElement).removeClass("activating")
    $(e.target.parentElement).addClass("active")
  });
  $(collapsible).on('hide.bs.collapse', function (e) {
    $(e.target.parentElement).removeClass("active")
    $(e.target.parentElement).addClass("closing")
  });
  $(collapsible).on('hidden.bs.collapse', function(e) {
    $(e.target.parentElement).removeClass("closing")
  });
}
