export const recaptchaCallback = () => {
  try {
    $.each( $('.g-recaptcha'), function() {
      const recaptchaNumber = $(this).data('init');
      if (recaptchaNumber != undefined) {
        // Caso o recaptcha já tiver sido usado da um reset
        grecaptcha.reset(recaptchaNumber); // eslint-disable-line

      } else {
        // Inicia recaptcha
        $(this).data('init', 
          grecaptcha.render( $(this).get(0) , { // eslint-disable-line
            'sitekey': '6LeNSPEdAAAAADPemjheIYbjrzlRpo0ysZeecG4t'
          })
        );
      }
    })
  }
  catch (err) {
    // imprime erro e chama função novamente
    console.log(err)
    setTimeout( recaptchaCallback, 500 )
  }
}