// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = window.$ = require('jquery');
window.Popper = require('popper.js')

require("@rails/ujs").start()
require("turbolinks").start()
// require("channels")

// >>>>>>>>>>  Plugins  <<<<<<<<<<
// Enable Fancybox
// import "../website/plugins/fancybox";

// Bootstrap
import "bootstrap";

// Ícones
import "@fortawesome/fontawesome-free/js/all";

// Carousel Slick
import "slick-carousel";

// jQuery Masks
import "../website/plugins/jquery-masks";

// JS - Toast
import Swal from 'sweetalert2';

// >>>>>>>>>>  Custom CSS  <<<<<<<<<<
require('../website/stylesheets/website.sass')


// >>>>>>>>>>  Custom JS  <<<<<<<<<<
import {
  accessibilityFunctions
} from "../website/components/accessibility"

import {
  initCollapsible
} from "../website/components/collapsible"

import {
  destroyCarousel,
  initCarouselBanner,
  initCarouselDepositions,
  initCarouselBlog,
  initCarouselWhereAreWe,
  initCarouselHistory,
  initCarouselStudyPlaces,
  initCarouselDifferentials,
  initCarouselSchoolDifferentials,
  initCarouselArticle
} from "../website/components/slick-carousel"

import {
  handleModaisPDF,
  handleModalPrograms,
  handleModalScheduleVisit,
  handleModalTeacher,
  handleModalFeminhaClasses
} from "../website/components/modal"

import {
  loadMoreFreeCourses,
  loadMorePostGraduations,
  loadMoreOfficeServices,
  loadMoreTeachers,
} from "../website/components/load-more"

import {
  setSameHeight
} from "../website/components/set-same-height"

import {
  filterArticles,
  paginateArticles
} from "../website/components/filter-articles"

import {
  selectCourse
} from "../website/components/selects"

import {
  ajaxSearch
} from "../website/components/search"

import {
  recaptchaCallback
} from "../website/components/recaptcha"

import { cookieAccept } from "../website/components/cookies"

// Dispara evento quando carregar a pagina
document.addEventListener("turbolinks:load", (e) => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()

  accessibilityFunctions();

  // Carousel
  initCarouselBanner();
  initCarouselDepositions();
  initCarouselBlog();
  initCarouselWhereAreWe();
  initCarouselStudyPlaces();
  initCarouselDifferentials();
  initCarouselHistory();
  initCarouselSchoolDifferentials();
  initCarouselArticle();

  // Modals
  handleModaisPDF();
  handleModalPrograms();
  handleModalScheduleVisit();
  handleModalTeacher();
  handleModalFeminhaClasses();

  // Load by AJAX
  loadMoreFreeCourses();
  loadMorePostGraduations();
  loadMoreOfficeServices();
  loadMoreTeachers();

  // Collapsible
  initCollapsible('#documentsCategories');
  initCollapsible('#feminhaClassroom');

  // Filtros
  filterArticles();
  paginateArticles();

  // Selects
  selectCourse();

  // Pesquisa do header
  ajaxSearch();

  // Callback do Recaptcha
  recaptchaCallback();

  // Envia os dados ao Google Analytics
  gtag('config', 'UA-141878006-1', {'page_location': e.data.url}); // eslint-disable-line

  // Mensagem de cookies
  cookieAccept();

  // Executado depois que a pagina ser carregada
  $(document).ready(
    () => {
      // setSameHeight
      setSameHeight('circle');
      setSameHeight('course');
    }
  )
})

document.addEventListener("turbolinks:before-cache", () => {
  // resolve bug do carrossel que nao renderiza quando muda de pagina
  destroyCarousel();
})

// Despara evento sempre que for redimencionado a pagina
window.addEventListener('resize', () => {

  // setSameHeight
	setSameHeight('circle');
  setSameHeight('course');
});

// Declara funções para poder usar diretamente nas views
window.filterArticles = filterArticles;
window.paginateArticles = paginateArticles;
window.recaptchaCallback = recaptchaCallback;
window.Swal = Swal;