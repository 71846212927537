import * as cookies from './cookies';

// Funções de Acessibilidade
export const accessibilityFunctions = () => {

  // S - Função para mudar tamanho da fonte
  const changeFontSize = (value) => {
    $("html").css({"font-size": value});
  }
  // E - Função para mudar tamanho da fonte

  // S - Função para ativar alto contraste
  const setHighContrast = (hc) => {
    let body = $("body")
    hc ? body.addClass("high-contrast") : body.removeClass("high-contrast");
  }
  // E - Função para ativar alto contraste


  // PEGAR OS COOKIES
  let fsCookie = cookies.getCookie("fontSize");
  let hcCookie = cookies.getCookie("highContrast");
  // transformar string em bool
  hcCookie = (hcCookie === "true");

  (fsCookie) ? changeFontSize(fsCookie) : "";
  (hcCookie) ? setHighContrast(hcCookie) : "";

  // Mudar tamanho da fonte
  $('.fs-btn').on('click', function() {
    let fs = $("html").css("font-size");
    let size = parseInt(fs);
    
    if ($(this).hasClass("plus")) {
      fs = (size < 20) ? size + 1 + "px" : fs
    } else {
      fs = (size > 16) ? size - 1 + "px" : fs
    }

    cookies.setCookie("fontSize", fs);
    changeFontSize(fs);
  });

  // Acionar alto contraste de cores
  $('.btn-contrast').on('click', function() {
    let isHighContrastOn = ($("body").hasClass("high-contrast")) ? true : false;

    (isHighContrastOn) ? (
      setHighContrast(false),
      cookies.setCookie("highContrast", false)
    ) : (
      setHighContrast(true),
      cookies.setCookie("highContrast", true)
    );
  });
}