export const filterArticles = () => {
  $('#filter-articles').off().change(
    event => {
      // Define pagina como 1
      $('#filter_artiles_page').val( 1 );

      // Chama função de filtro
      sendFilter(event)
    }
  )
  $('#filter-articles').off().submit(
    event => {
      // Define pagina como 1
      $('#filter_artiles_page').val( 1 );

      // Chama função de filtro
      sendFilter(event)
    }
  )
}

export const paginateArticles = () => {
  $('.pagination a').off().click(
    function(event) {
      // Obtem parametro de pagina
      const pageParams = new URL(
        `${ window.location.origin }/${ $(this).attr('href') }`
      ).searchParams.get('page')

      // Define para qual pagina deveria ir
      $('#filter_artiles_page').val( pageParams );

      // Chama função de filtro
      sendFilter(event)
    }
  )
}

const sendFilter = event => {
  event.preventDefault();
  event.stopPropagation();

  let data = {
    search:   $('#filter_artiles_search').val(),
    category: $('#filter_artiles_category').val(),
    page:     $('#filter_artiles_page').val(),
  }

  $.ajax({
    url: '/ajax/filter_articles',
    method: 'post',
    dataType: 'script',
    data: data,
  }).done(
    () => {
      $.each(data, (key, value) => { if(!value) delete data[key] });
      history.replaceState(data, "Filtrando Notícias", `${ window.location.pathname }?${ $.param(data) }` );
    }
  );
}