/*
  DOC: https://igorescobar.github.io/jQuery-Mask-Plugin/docs.html
*/
import 'jquery-mask-plugin/dist/jquery.mask';

const initMasks = () => {
  $('.phone').mask('(00) 00000-0000');
  $('.cnpj').mask('00.000.000/0000-00', {reverse: true});
}
  
document.addEventListener('turbolinks:load', initMasks);