// Funções para pegar e setar cookies

export const cookieAccept = () => {
  var cookie = getCookie('cookie_message');

  if ( !cookie.length ){
    $('#modal-cookies-message').modal({ backdrop: false });
    $('body').removeClass('modal-open');
    $('body').css('padding', 0);
  }

  $('.cookie-accept').click(function(){
    setCookie('cookie_message', 'accepted');
    $('#modal-cookies-message').modal('hide');
  })
}

export function setCookie(name, value) {
  const expire_data = new Date();
  expire_data.setFullYear( expire_data.getFullYear() + 1 );

  document.cookie = `${name}=${value}; expires=${expire_data.toUTCString()}; path=/`
}

export function getCookie(name) {
  var value = '';
  var cookie = document.cookie.split('; ').find( function(row){
    return row.startsWith(name);
  })

  if (cookie){
    value = cookie.split('=')[1]
  }
  return value;
}