export const initCarouselBanner = () => {
  if ( !$('#slick-carousel-banner').hasClass('slick-initialized') ) {
    $('#slick-carousel-banner').slick({
      infinite: false,
      dots: true,
      arrows: true,
      prevArrow: '<div class="box-arrows prev-arrow white-border"><i class="slick-btns slick-prev material-icons"> arrow_back </i></div>',
      nextArrow: '<div class="box-arrows next-arrow white-border"><i class="slick-btns slick-next material-icons"> arrow_forward </i></div>',
    });
  }
}

export const initCarouselHistory = () => {
  if ( !$('.slick-carousel-history').hasClass('slick-initialized') ) {
    $('.slick-carousel-history').slick({
      infinite: true,
      dots: true,
      slidesToShow: 3,
      arrows: true,

      prevArrow: '<div class="box-arrows prev-arrow white-border"><i class="slick-btns slick-prev material-icons"> arrow_back </i></div>',
      nextArrow: '<div class="box-arrows next-arrow white-border"><i class="slick-btns slick-next material-icons"> arrow_forward </i></div>',

      responsive: [
        {
          breakpoint: 720,
          settings: {
              slidesToShow: 1,
          }
        },
      ]
    });
  }
}

export const initCarouselDepositions = () => {
  if ( !$('#slick-carousel-depositions').hasClass('slick-initialized') ) {
    $('#slick-carousel-depositions').slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      vertical: true,
      swipe: false,

      dots: true,
      appendDots: '.content-controllers-depositions .dots',

      arrows: true,
      prevArrow: '',
      nextArrow: '<div class="box-arrows next-arrow white-border"><i class="slick-btns slick-next material-icons"> arrow_forward </i></div>',
      appendArrows: '.content-controllers-depositions',
      
      responsive: [
        {
          breakpoint: 720,
          settings: {
              slidesToShow: 1,
          }
        },
      ]
    });
  }
}

export const initCarouselBlog = () => {
  if (!$('#slick-carousel-blog').hasClass('slick-initialized')) {
    $('#slick-carousel-blog').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: false,
      prevArrow: '<div class="box-arrows prev-arrow white-border"><i class="slick-btns slick-prev material-icons"> arrow_back </i></div>',
      nextArrow: '<div class="box-arrows next-arrow white-border"><i class="slick-btns slick-next material-icons"> arrow_forward </i></div>',
      dotsClass: 'slick-dots',
      dots: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
}

export const initCarouselWhereAreWe = () => {
  if (!$('#slick-carousel-where-are-we').hasClass('slick-initialized')) {
    $('#slick-carousel-where-are-we').slick({
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: false,
      prevArrow: '<i class="slick-btns slick-prev material-icons"> keyboard_arrow_left </i>',
      nextArrow: '<i class="slick-btns slick-next material-icons"> keyboard_arrow_right </i>',
      dotsClass: 'slick-dots',
      dots: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    });
  }
}

export const initCarouselStudyPlaces = () => {
  if ( !$('#slick-carousel-study-places-text').hasClass('slick-initialized') ) {
    $('#slick-carousel-study-places-text').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      appendDots: '.content-controllers .dots',
      dots: true,
      prevArrow: '<i class="arrow-controller opacity material-icons"> arrow_back </i>',
      nextArrow: '<i class="arrow-controller opacity material-icons"> arrow_forward </i>',
      appendArrows: '.content-controllers',
      asNavFor: '#slick-carousel-study-places-image'
    });
  }
  
  if ( !$('#slick-carousel-study-places-image').hasClass('slick-initialized') ) {
    $('#slick-carousel-study-places-image').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      dots: false,
      arrows: false,
      asNavFor: '#slick-carousel-study-places-text'
    });
  }

  // Quando muda o slide no carrossel, muda a cor de fundo de acordo com o data-color no elemento
  $('#slick-carousel-study-places-text').on('beforeChange', function(event, slick, currentSlide, prevSlide){
    // pega data-color do elemento que vai ficar visível
    let color = $(`[data-slick-index='${prevSlide}'] .content-item`).data("color")
    // seta cor do fundo de carrosel
    $("section#section-carousel").css("background", `var(--bg-high-contrast, ${ color })` )
  });
}

export const initCarouselDifferentials = () => {
  if ( !$('#slick-carousel-differentials').hasClass('slick-initialized') ) {
    $('#slick-carousel-differentials').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      appendDots: '.content-controllers .dots',
      dotsClass: 'slick-controllers',
      dots: true,
      prevArrow: '<i class="arrow-controller material-icons"> keyboard_arrow_left </i>',
      nextArrow: '<i class="arrow-controller material-icons"> keyboard_arrow_right </i>',
      appendArrows: '.content-controllers',

    });
  }

  $('#slick-carousel-differentials').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    $(`.differentials li[data-target="slide-${currentSlide}"]`).removeClass('active')
    $(`.differentials li[data-target="slide-${nextSlide}"]`).addClass('active')
  });
}

export const initCarouselSchoolDifferentials = () => {
  if ( !$('#slick-carousel-school-differentials').hasClass('slick-initialized') ) {
    $('#slick-carousel-school-differentials').slick({
      infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      fade: true,
      cssEase: 'linear',
      dots: false,
      arrows: false
    });
  }

  $("ul.differentials .controls").on('click', (e) => {
    let itemList = $(e.target);
    let slide = itemList.data("target");

    // Atualiza item da lista ativo
    $("ul.differentials .controls.active").removeClass('active');
    $(itemList).addClass('active');

    // Muda slide ativo
    $('#slick-carousel-school-differentials').slick('slickGoTo', slide);
  })
}

export const initCarouselArticle = () => {
  if ( !$('#slick-carousel-article').hasClass('slick-initialized') ) {
    $('#slick-carousel-article').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: false,
      prevArrow: '<div class="box-arrows prev-arrow white-border"><i class="slick-btns slick-prev material-icons"> arrow_back </i></div>',
      nextArrow: '<div class="box-arrows next-arrow white-border"><i class="slick-btns slick-next material-icons"> arrow_forward </i></div>',
      fade: true,
      asNavFor: '#slick-carousel-article-miniature'
    })
  }

  if ( !$('#slick-carousel-article-miniature').hasClass('slick-initialized') ) {
    $('#slick-carousel-article-miniature').slick({
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      draggable: false,
      arrows: false,
      focusOnSelect: true,
      asNavFor: '#slick-carousel-article',
      responsive: [
        {
          breakpoint: 720,
          settings: {
            slidesToShow: 2,
          }
        }
      ]
    })
  }
}

export const destroyCarousel = () => {
  $('#slick-carousel-banner').slick('unslick');
  $('#slick-carousel-depositions').slick('unslick');
  $('#slick-carousel-blog').slick('unslick');
  $('#slick-carousel-where-are-we').slick('unslick');
  $('.slick-carousel-history').slick('unslick');
  $('#slick-carousel-study-places-text').slick('unslick');
  $('#slick-carousel-study-places-image').slick('unslick');
  $('#slick-carousel-differentials').slick('unslick');
  $('#slick-carousel-school-text').slick('unslick');
  $('#slick-carousel-school-image').slick('unslick');
  $('#slick-carousel-schools-differentials').slick('unslick');
  $('#slick-carousel-article').slick('unslick');
  $('#slick-carousel-article-miniature').slick('unslick');
}
