import isPresent from '~/utils/isPresent';

export const handleModaisPDF = () => {
  $('.modalPDF').on('show.bs.modal', (e) => {
    var modal = $(e.currentTarget);
    let type = $(e.relatedTarget).data('type');
    let page = $(e.relatedTarget).data('page');

    // Faz a Requisição AJAX
    $.ajax({
      url: `/ajax/modal_pdf/${type}`,
      method: 'get',
      data: {
        page: page,
      },
      beforeSend: function () {
        modal.find(".modal-body").hide();
        modal.find(".loader").show();
      },
      success: function() {
        modal.find(".loader").hide();
        modal.find(".modal-body").show();
      },
    })
    .done(function(obj) {
      if (!obj.length){
        modal.find("ul.pdfs-tab").hide();
        modal.find(".pdfs-content").append("<p class='text-center'>Não foi possivel encontrar nenhum arquivo<p>");
      } else {
        // Loop nas categorias
        $.each( obj, (i, info) => {
          // Cria item do menu para cada categoria
          let itemList = `
            <li class="nav-item" role="presentation">
              <a 
                class="nav-link white-border ${i == 0 ? 'active' : ''}" 
                id="${type}-${i}-tab" 
                data-toggle="pill" 
                href="#category-${type}-${i}" 
                role="tab" 
                aria-controls="category-${type}-${i}" 
                aria-selected="true"
              >
                ${info.category}
              </a>
            </li>
          `
          modal.find("ul.pdfs-tab").append(itemList);
          modal.find("ul.pdfs-tab").show();

          // Cria tab para armazenar pdfs para cada categoria
          let tabCategory = `
            <div 
              class="tab-pane fade py-md-3 ${i == 0 ? 'show active' : ''}" 
              id="category-${type}-${i}" 
              role="tabpanel" 
              aria-labelledby="${type}-${i}-tab"
            >
            </div>
          `
          modal.find(".pdfs-content").append(tabCategory);

          // Loop nos arquivos de cada categoria
          $.each( info.files, (j, file) => {
            // Cria elemento do link
            let pdf = `
              <div class="content-item py-3">
                <a class="link-item" href="${file.href}" target="_blank" title="Abre PDF">
                  <p>${file.title}</p>
                  ${file.coordinator ? `<span>${file.coordinator}</span>` : ``}
                </a>
              </div>
            `
            modal.find(`#category-${type}-${i}`).append(pdf);
          })
        })
      }
    })
  })

  $('.modalPDF').on('hidden.bs.modal', (e) => {
    var modal = $(e.currentTarget);
    modal.find("ul.pdfs-tab").empty();
    modal.find(".pdfs-content").empty();
  })
}

export const handleModalPrograms = () => {
  $('#modalPrograms').on('show.bs.modal', (e) => {
    var modal = $(e.currentTarget);
    let ajaxUrl = $(e.relatedTarget).data('link');

    $.ajax({
      url: ajaxUrl,
      method: 'get',
      beforeSend: function () {
        modal.find(".modal-body").hide();
        modal.find("#loader").show();
      },
      success: function() {
        modal.find("#loader").hide();
        modal.find(".modal-body").show();
      },
    })
    .done(function(obj) {
      modal.find("img#image").attr('src', obj?.get_image_url);

      modal.find("h3#title").html(obj?.title);
      modal.find("p#description").html(obj?.description);

      modal.find("h6#subtitle1").html(obj?.subtitle1);
      modal.find("p#description1").html(obj?.description1);

      modal.find("h6#subtitle2").html(obj?.subtitle2);
      modal.find("p#description2").html(obj?.description2);

      if (isPresent(obj?.registration_url)) {
        modal.find("a.cadastre-se-enlgish").removeClass('d-none');
        modal.find("a.cadastre-se-enlgish").attr('href', obj?.registration_url);
      } else {
        modal.find("a.cadastre-se-enlgish").addClass('d-none');
      }

      modal.find("a[data-target='#modal-english-interest']").data('english-class', obj?.title_and_subtitle)
    })
  })
  $('#modal-english-interest').on('show.bs.modal', (e) => {
    let inputEnglishModal = $(e.currentTarget).find('#english_interest_english_class')
    let valueEnglishClass = $(e.relatedTarget).data('english-class')
    inputEnglishModal.val( valueEnglishClass )
    
    $('#modalPrograms').modal('hide');
  })
}

export const handleModalScheduleVisit = () => {
  $('#modalScheduleVisit').on('show.bs.modal')
}

export const handleModalTeacher = () => {
  $('#modalTeachers').on('show.bs.modal', (e) => {
    var modal = $(e.currentTarget);
    let ajaxUrl = $(e.relatedTarget).data('link');

    $.ajax({
      url: ajaxUrl,
      method: 'get',
      beforeSend: function () {
        modal.find(".modal-body").hide();
        modal.find("#loader").show();
      },
      success: function() {
        modal.find("#loader").hide();
        modal.find(".modal-body").show();
      },
    })
    .done(function(obj) {
      modal.find("img#image").attr('src', obj?.image_url);

      modal.find("h3#titlemodalTeachers").html(obj?.name);
      modal.find("p#description-text").html(obj?.description);
    })
  })
}

export const handleModalFeminhaClasses = () => {
  $('#modalFeminhaClasses').on('show.bs.modal', (e) => {
    var modal = $(e.currentTarget);
    let ajaxUrl = $(e.relatedTarget).data('link');

    $.ajax({
      url: ajaxUrl,
      method: 'get',
      beforeSend: function () {
        modal.find(".modal-body").hide();
        modal.find("#loader").show();
      },
      success: function() {
        modal.find("#loader").hide();
        modal.find(".modal-body").show();
      },
    })
    .done(function(obj) {
      modal.find("img#image").attr('src', obj?.get_image_url);
      modal.find("h3#title").html(obj?.title);
      modal.find("p#description").html(obj?.description);
    })
  })
}