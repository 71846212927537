export const loadMoreFreeCourses = () => {
  $('a.btn-load-more').on( 'click', () => {
    let start = $('.card-free-course').length;
    
    $.ajax({
      url: `/ajax/load_more_free_courses/${start}`,
      method: 'get',
      beforeSend: function () {
        $("#loader").show();
      },
      success: function() {
        $("#loader").hide();
      }
    }).done(function(obj){
      if(obj.data || obj.length < 4){
        $('.btn-load-more').attr("style", "display: none !important");
      }
      if(!obj.data){
        $.each( obj, (i, obj) => {
          let cardFreeCourse = `
            <div class="col-6 col-lg-3 col-md-4 col-sm-6 py-3">
              <a href="/cursos-livres/${obj.slug}" role="button" title="Curso de ${obj.title}">
                <div class="card-free-course h-100">
                  <div class="header-free-course">
                    <div class="image-free-course">
                        <img src=${obj.image_url ? obj.image_url : '/no-image.png'} class='w-100' alt="Banner Cursos Livres - Fema"> 
                    </div>
                    <div class="tag p-2">
                      <p class="font-barlow size-12">
                        ${obj.category}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column justify-content-between content-free-course">
                    <div class="course-name p-3">
                      <strong class="font-barlow size-14">
                        ${obj.title}
                      </strong>
                    </div>
                    <div class="d-flex course-date p-3">
                      <div class="col-1 d-flex align-items-center justify-content-end">
                        <i class="far fa-calendar icon" aria-hidden="true"></i>
                      </div>
                      <div class="col-11 d-flex align-items-center date-description p-0">
                        <p class="font-barlow size-12">
                          ${obj.start_end_dates}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          `
          $("#list-of-courses").append(cardFreeCourse);
        })
      }
    })
  })
}

export const loadMorePostGraduations = () => {
  $('a.link-see-more').on( 'click', () => {
    let start = $('.card-postgraduation-courses').length;
    
    $.ajax({
      url: `/ajax/load_more_post_graduations/${start}`,
      method: 'get',
      beforeSend: function () {
        $("#loader").show();
      },
      success: function() {
        $("#loader").hide();
      }
    }).done(function(obj){
      if(obj.data || obj.length < 3){
        $('.link-see-more').attr("style", "display: none !important");
      }
      if(!obj.data){
        $.each( obj, (i, obj) => {
          let cardPostGraduation = `
            <div class="col-12 col-md-6 col-lg-4 py-2 py-md-4">
              <a href="pos-graduacao/${obj.slug}" class="d-block px-4 p-md-0" role="button" title="Acessar página de curso ${obj.title}">
                <div class="card-postgraduation-courses card white-border">
                  <div class="content-image">
                      <img src="${obj.image_url ? obj.image_url : '/no-image.png'}" class="w-100">
                  </div>
                  <div class="card-body d-flex flex-column justify-content-between font-barlowsemicondensed">
                    <div class="content-title">
                      <span class="status size-14 ${obj.color}">
                        ${obj.status}
                      </span>
                      <h3 class="card-title size-20 pt-2">
                        ${obj.title}
                      </h3>
                    </div>
                    <div class="content-date d-inline-flex align-items-center">
                      <i class="far fa-calendar icon mr-3" aria-hidden="true"></i>
                      <div class="date">
                        ${obj.date}
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          `;
          $("#list-of-courses").append(cardPostGraduation);
        })
      }
    })
  })
}

export const loadMoreOfficeServices = () => {
  $(".load-more-services").on('click', (e) => {
    let office = $(e.target).data("office");
    let list = $(e.target).closest(".services")

    $.ajax({
      url: '/ajax/load_more_office_services',
      method: 'get',
      data: {
        office: office
      },
      beforeSend: () => {
        $(".office#loader").show();
      },
      success: () => {
        $(".office#loader").hide();
        $(e.target).hide();
      }
    }).done( (obj) => {
      let services = "";

      $.each( obj, (i, obj) => {
        services += `<li>${ obj }</li>`;
      })

      $(list).find("ul").append(services);
    })
  })
}

export const loadMoreTeachers = () => {
  $('a.link-see-more-teachers').on( 'click', (e) => {
    let start = $('.card-teacher').length;
    let page = $(e.target).data('page');
    
    $.ajax({
      url: `/ajax/${page}/load_more_teachers/${start}`,
      method: 'get',
      beforeSend: function () {
        $("#loader").show();
      },
      success: function() {
        $("#loader").hide();
      }
    }).done(function(obj){
      if(obj.data || obj.length < 4){
        $('.load-more').attr("style", "display: none !important");
      }
      if(!obj.data){
        $.each( obj, (i, obj) => {
          let cardTeacher = `
            <div class="col-12 col-md-6 col-lg-3 card-teacher">
              <a href="javascript:;" data-toggle="modal" data-target="#modalTeachers" data-link="${obj.data_link}" role="button" title="Abre modal com informações do professor">
                <div class="teacher my-3">
                  <img src="${obj.image_url ? obj.image_url : '/no-image.png'}" title="${obj.image_url ? 'Imagem do Professor - FEMA' : 'Sem foto'}">
                  <div class="content-teacher ml-4">
                    <div class="name">
                      ${obj.name}
                    </div>
                    <div class="profission size-14">
                      Professor
                    </div>
                  </div>
                </div>
              </a>
            </div>
          `;
          $("#list-teachers").append(cardTeacher);
        })
      }
    })
  })
}