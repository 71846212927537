export const ajaxSearch = () => {
  var searchInput = $('#search');

  searchInput.on('keyup', () => {
    if (searchInput.val() != "") {
      searchAjax();
    } else {
      searchInput.dropdown('hide');
    }
  })


  $(document).on('click', () => {
    searchInput.dropdown('hide');
  })

  // ao clicar novamente no campo, abrir o dropdown novamente
  // se houver valor no input
  searchInput.on('click', (e) => {
    if (searchInput.val() != ""){
      searchInput.dropdown('show');
      e.stopPropagation();
    }
  })
}

const searchAjax = () => {
  var dropdown = $('#search-results');
  var searchInput = $('#search');
  var timer;

  clearTimeout(timer);

  timer = setTimeout(function() {
    // Faz a Requisição AJAX
    $.ajax({
      url:'/ajax/search',
      method: 'get',
      data: {
        search: searchInput.val(),
      },

      beforeSend: function () {
        searchInput.dropdown('show');
        dropdown.find(".results-list").hide();
        dropdown.find("#loader").show();
      },
      success: function() {
        dropdown.find("#loader").hide();
        dropdown.find(".results-list").show();
      },
    })  
  }, 300);
}